import React from "react";
// import Blog from "./components-home/Blog";
import Preface from "./components-home/Preface";
import Contact from "./components-home/Contact";
import Feature from "./components-home/Feature";
// import FeatureOne from "./components-home/FeatureOne";
// import FeatureTwo from "./components-home/FeatureTwo";
import Heroarea from "./components-home/Heroarea";
import Pricing from "./components-home/Pricing";
// import Testimonials from "./components-home/Testimonials";
// import Services from "./components-home/Services";
// import Preface from "./components-home/Preface";
import Howitworks from "./components-home/Howitworks";
import Screenshots from "./components-home/Screenshots";

import Header from './components-global/Header';
import Footer from './components-global/Footer';
import Footerdata from '../data/footerdata.json';

const Home = () => {
  return (
    <div>
      <Header />
      <Heroarea />
      <Preface />
      <Howitworks />
      <Feature />
      {/* <FeatureOne /> */}
      {/* <FeatureTwo /> */}
      {/* <Services /> */}
      <Screenshots />
      <Pricing />
      {/* <Testimonials /> */}
      {/* <Blog /> */}
      <Contact />
      <Footer data={Footerdata} />
    </div>
  );
};

export default Home;
