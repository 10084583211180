import React from 'react';

import { ReactComponent as Logo } from '../logo-full.svg';

import Footerdata from '../data/footerdata.json';

export default class ComingSoon extends React.Component {
  static defaultProps = {
    data: Footerdata,
  };

  render() {
    let data = this.props.data;

    return (
      <div className="ComingSoon">
        <footer id="footer" className="p-70px-t p-30px-b footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="footer-top text-center p-30px-tb">
                  <Logo />
                  <p>{data.footertext}</p>
                  <div className="social-network">
                    {data.socialicon.map((data, i) => {
                      return (
                        <a key={i} href={data.url}>
                          <i className={` social-icon ${data.icon} `}></i>
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className="footer-copyright p-30px-tb text-center">
                  <p dangerouslySetInnerHTML={{ __html: data.copyrighttext }}></p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}